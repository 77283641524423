import React, { useEffect, useRef, useState } from "react";
import "../styles/LanguageSphere.css";
import {
  siJavascript,
  siPython,
  siTypescript,
  siReact,
  siNodedotjs,
  siMysql,
  siWebpack,
  siBabel,
  siExpress,
  siNextdotjs,
  siRedux,
  siTailwindcss,
  siMaterialdesign,
  siHtml5,
  siCss3,
  siJest,
  siAmazonwebservices,
  siGithub,
  siJira,
  siConfluence,
  siJenkins,
  siSentry,
  siR,
  siVercel,
  siLangchain,
} from "simple-icons";

const languages = [
  { name: "TypeScript", icon: siTypescript },
  { name: "JavaScript", icon: siJavascript },
  { name: "Python", icon: siPython },
  { name: "ReactJs", icon: siReact },
  { name: "Nodejs", icon: siNodedotjs },
  { name: "MySQL", icon: siMysql },
  { name: "Webpack", icon: siWebpack },
  { name: "Babel", icon: siBabel },
  { name: "Express", icon: siExpress },
  { name: "Next.js", icon: siNextdotjs },
  { name: "Redux", icon: siRedux },
  { name: "Tailwind CSS", icon: siTailwindcss },
  { name: "Material Design", icon: siMaterialdesign },
  { name: "HTML5", icon: siHtml5 },
  { name: "CSS3", icon: siCss3 },
  { name: "Jest", icon: siJest },
  { name: "Amazon Web Services", icon: siAmazonwebservices },
  { name: "Github", icon: siGithub },
  { name: "Jira", icon: siJira },
  { name: "Confluence", icon: siConfluence },
  { name: "Jenkins", icon: siJenkins },
  { name: "Sentry.io", icon: siSentry },
  { name: "R", icon: siR },
  { name: "Vercel", icon: siVercel },
  { name: "Langchain", icon: siLangchain },
];

const radius = 250; // radius of the sphere

const getSphereItemStyle = (index, total) => {
  const phi = Math.acos(-1 + (2 * index) / total); // Calculate phi angle
  const theta = Math.sqrt(total * Math.PI) * phi; // Calculate theta angle

  const x = radius * Math.sin(phi) * Math.cos(theta); // X coordinate
  const y = radius * Math.sin(phi) * Math.sin(theta); // Y coordinate
  const z = radius * Math.cos(phi); // Z coordinate

  return {
    transform: `translate3d(${x}px, ${y}px, ${z}px)`,
  };
};

const LanguageSphere = () => {
  const sphereRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [initialMousePos, setInitialMousePos] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState({ x: 0, y: 0 });
  const [autoRotate, setAutoRotate] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (isDragging) {
        const { clientX, clientY } = event;
        const deltaX = clientX - initialMousePos.x;
        const deltaY = clientY - initialMousePos.y;

        setRotation((prev) => ({
          x: prev.x + deltaY * 0.03, // Reduced rotation speed
          y: prev.y + deltaX * 0.03, // Reduced rotation speed
        }));

        setInitialMousePos({ x: clientX, y: clientY });
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, initialMousePos]);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setInitialMousePos({ x: event.clientX, y: event.clientY });
  };

  useEffect(() => {
    const animate = () => {
      setAutoRotate((prev) => ({
        x: prev.x + 0.0, // No rotation around the X-axis for horizontal rotation
        y: prev.y + 0.03, // Reduced speed of rotation around the Y-axis
      }));

      requestAnimationFrame(animate);
    };

    animate();
  }, []);

  const total = languages.length;

  return (
    <div className="sphere-container" onMouseDown={handleMouseDown}>
      <div
        className="sphere"
        ref={sphereRef}
        style={{
          transform: `rotateX(${rotation.x + autoRotate.x}deg) rotateY(${
            rotation.y + autoRotate.y
          }deg)`,
        }}
      >
        {languages.map((language, index) => (
          <div
            key={index}
            className="sphere-item"
            style={getSphereItemStyle(index, total)}
          >
            <div
              dangerouslySetInnerHTML={{ __html: language.icon.svg }}
              title={language.name}
              style={{
                fill: `#${language.icon.hex}`,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LanguageSphere;
