import React from "react";
import "../styles/About.css";
import FadeInSection from "./FadeInSection";

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey,
    });
  }
  render() {
    const one = (
      <p>
        I am a graduate with a{" "}
        <a
          href="https://www.mcgill.ca/desautels/programs/mma"
          target="_blank"
          rel="noopener noreferrer"
        >
          Master of Management in Analytics
        </a>{" "}
        from the <b>Desautels Faculty of Management, McGill University</b>.
        Before embarking on my graduate studies, I have accumulated five years
        of professional experience in the industry, working with notable
        companies and early stage startups such as{" "}
        <a
          href="https://www.tempolabs.ai/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Tempo Labs
        </a>
        ,{" "}
        <a
          href="https://kpidigital.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          KPI Digital Solutions
        </a>
        ,{" "}
        <a
          href="https://www.phonepe.com/about-us/"
          target="_blank"
          rel="noopener noreferrer"
        >
          PhonePe
        </a>
        ,{" "}
        <a
          href="https://www.linkedin.com/company/crejo-fun/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Crejo.Fun
        </a>
        , and{" "}
        <a
          href="https://www.hsbc.co.in/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Hong Kong & Shanghai Banking Corportation (HSBC)
        </a>
        .
      </p>
    );
    const two = (
      <p>
        Outside of work, I'm always keeping up with the latest tech trends. When
        I'm not behind a screen, you'll probably find me playing Table Tennis. I
        also play a lot of Dota2. I'm a big cricket fan and love catching games
        whenever I can!
      </p>
    );

    const tech_stack = [
      "Typescript",
      "React.js",
      "Node.js",
      "Javascript",
      "OpenAI API",
      "Python",
      "SQL",
      "LangChain",
    ];

    return (
      <div id="about">
        <FadeInSection>
          <div className="section-header ">
            <span className="section-title">&lt;About Me/&gt;</span>
          </div>
          <div className="about-content">
            <div className="about-description">
              {[one]}
              {"Here are some technologies I have been working with:"}
              <ul className="tech-stack">
                {tech_stack.map(function (tech_item, i) {
                  return (
                    <FadeInSection delay={`${i + 1}00ms`}>
                      <li style={{ fontSize: "20px", letterSpacing: "1px" }}>
                        {tech_item}
                      </li>
                    </FadeInSection>
                  );
                })}
              </ul>
              {[two]}
            </div>
            <div className="about-image">
              <img alt="Jatin Suri" src={"/assets/me.jpeg"} />
            </div>
          </div>
        </FadeInSection>
      </div>
    );
  }
}

export default About;
